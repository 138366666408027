<template>
  <div>
    <template v-for="(brand, index) in brands">
      <v-row>
        <v-col>
          <BrandsSearch
            v-model="brands[index]"
            @input="handleInput($event, index)"
            @clear="removeRow(index)"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import BrandsSearch from "../../brands/ui/BrandsSearch.vue";

export default {
  components: { BrandsSearch },

  props: {
    value: {},
  },

  data: () => ({
    brands: [{}],
  }),

  computed: {
    hasMoreThanOneRow() {
      return this.brands.length > 1;
    },
  },

  watch: {
    value(val) {
      this.setBrands(val);
    },

    brands: {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  created() {
    this.setBrands(this.value);
  },

  methods: {
    setBrands(value) {
      this.brands = value;
    },

    removeRow(index) {
      this.$delete(this.brands, index);
    },

    handleInput(value, index) {
      if (this.isLastRow(index) && value !== null) {
        this.addRow();
      }
    },

    isLastRow(index) {
      return index + 1 === this.brands.length;
    },

    addRow() {
      this.brands.push({
        brand: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>