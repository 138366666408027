<template>
  <div>
    <template v-for="(row, index) in comments">
      <div v-if="row.status == 1">
        <div class="d-flex justify-space-between">
          <div>
            <b>@{{ row.employee.short_name }}:</b>
          </div>
          <div>
            <v-btn
              class="text-capitalize"
              primary
              x-small
              text
              @click="handleRemoveComment(index)"
            >
              Remover
              <v-icon x-small>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-alert class="mt-1" color="grey lighten-4" dense>
          <div style="white-space: pre; font-size:14px">{{ row.comment }}</div>
        </v-alert>
      </div>
    </template>
    <v-btn
      class="text-capitalize"
      primary
      small
      text
      @click="handleAddComment()"
    >
      <v-icon>mdi-plus</v-icon>
      Adicionar Comentário</v-btn
    >

    <CommentForm ref="CommentForm" @input="handleCommentInput($event)" />
  </div>
</template>

<script>
import CommentForm from "@/components/comments/CommentForm.vue";

export default {
  components: { CommentForm },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    comments: [],
  }),

  created() {
    this.comments = this.value;
  },

  watch: {
    value() {
      this.comments = this.value;
    },
  },

  methods: {
    handleAddComment() {
      this.$refs.CommentForm.open();
    },

    handleCommentInput(comment) {
      this.comments.push(comment);
    },

    handleRemoveComment(index) {
      this.comments[index].status = 0
    }
  },
};
</script>

<style>
</style>