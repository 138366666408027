<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="dialog"
    width="400px"
  >
    <!-- <v-card> -->
    <v-card-title>Adicionar Comentário</v-card-title>
    <v-card-text class="mb-6">
      <v-row>
        <v-col>
          <span>@{{ user.short_name }}:</span>
          <v-textarea
            v-model="comment.comment"
            class="mt-4"
            lines="10"
            no-resize
            auto-grow
            solo
            hide-details="true"
            flat
            rows="10"
            background-color="grey lighten-4"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="dialog = false" text class="text-capitalize"
            >Cancelar</v-btn
          >
          <v-btn
            @click="handleSave()"
            class="text-capitalize mr-2"
            color="primary"
            >Salvar</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-navigation-drawer>
</template>
      

<script>
export default {
  props : {
    value : {}
  },

  data() {
    return {
      dialog: false,
  
      comment : {
        comment : null,
        user : null,
      },
    };
  },

  created() {
    this.reset();
  },

  computed : {
    user () {
      return this.$store.state.auth.userData.user
    }
  },

  methods: {
    async open(data = null) {
      this.reset();

      this.dialog = true;
    },

    reset() {
      this.comment = {
        status : 1,
        employee : this.user,
        comment : null,
      }
    },

    handleSave() {
      this.$emit('input', this.comment)
      this.dialog = false
    },

    // store() {
    //   this.$http.store("product/product", this.form).then((response) => {
    //     this.processSaved();
    //   });
    // },
  },
};
</script>

<style scoped>
</style>